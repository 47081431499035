import { AiOutlineHome, AiOutlineUser } from 'react-icons/ai'
import { BiMessageSquareDetail } from 'react-icons/bi'
import { BsLinkedin, BsFileCode, BsTwitterX } from 'react-icons/bs'
import { FaGithub } from 'react-icons/fa'
import { HiOutlinePhone } from 'react-icons/hi'
import { MdOutlineEmail, MdCalendarMonth } from 'react-icons/md'
import { SiCodeproject, SiUpwork } from 'react-icons/si'
import { TbRss } from "react-icons/tb"
import Wit_Warrior from '../../assets/witwarrior.webp'
import Shaadi_Vines from '../../assets/shaadivines.webp'
import Remember_It from '../../assets/calendar.webp'
import EdTech from '../../assets/edtech.webp'
import STUK from '../../assets/stuk.webp'
import Zillit from '../../assets/zillit.webp'
import KISS from '../../assets/editor.webp'
import ChatterType from '../../assets/terminal.webp'

let diff = (new Date().getTime() - new Date('01/07/2021').getTime()) / 1000
diff /= (60 * 60 * 24)

const LinkedInSocial = {
    link: 'https://www.linkedin.com/in/akshat-arora-007/',
    icon: <BsLinkedin />,
    label: 'LinkedIn',
}

export const totalXp = Math.abs(Math.floor(diff/365.25))

export const GitHubSocial = 'https://github.com/AksBad007/'

export const headerSocials = [
    LinkedInSocial,
    {
        link: GitHubSocial,
        icon: <FaGithub />,
        label: 'GitHub',
    }
]

export const navData = [
    {
        section: 'header',
        title: 'Home',
        icon: <AiOutlineHome />
    },
    {
        section: 'about',
        title: 'About Me',
        icon: <AiOutlineUser />
    },
    {
        section: 'skills',
        title: 'Skills',
        icon: <BsFileCode />
    },
    {
        section: 'projects',
        title: 'Projects',
        icon: <SiCodeproject />
    },
    {
        section: 'blogs',
        title: 'Blogs',
        icon: <TbRss />
    },
    {
        section: 'contact',
        title: 'Contact Me',
        icon: <BiMessageSquareDetail />
    }
]

export const projectsData = [
    {
        imgSrc: Wit_Warrior,
        title: 'Wit Warrior',
        desc: `Wit Warrior is a platform that generates targeted marketing content using Generative AI.`,
        demo: 'https://www.witwarrior.com/',
    },
    {
        imgSrc: Shaadi_Vines,
        title: 'Shaadi Vines',
        desc: `Shaadi Vines is all about transforming the wedding experience for couples and their guests,
                creating unforgettable moments, and simplifying the planning process.`,
        demo: 'https://www.shaadivines.com/',
    },
    {
        imgSrc: Remember_It,
        title: 'Remember It - A Calendar App',
        desc: `A Calendar App, made using Next.js and inspired by Google Calendar, allowing users to
                schedule events, add friends and more. It provides real-time updates and timely reminders
                for easy time-management.`,
        github: 'https://github.com/AksBad007/remember_it/',
        demo: 'https://cooperative-vast-albacore.glitch.me/',
    },
    {
        imgSrc: EdTech,
        title: 'EdTech Games',
        desc: `EdTech Games is an immersive online platform where students can learn concepts like Algebra and Fractions
                with the help of games. Additionally, The schools and teachers can manage and interpret the data generated by the students.`,
        demo: 'https://edtechgames.com/',
    },
    {
        imgSrc: STUK,
        title: 'STUK Software',
        desc: `STUK is a Tow Management Software that offers complete tow management software. We developed this software
                placing the business first, allowing a trouble free and transparent service to the customer.`,
        demo: 'https://www.stuksoftware.com/',
    },
    {
        imgSrc: ChatterType,
        title: 'Chatter-Type',
        desc: `Chatter-Type is a terminal-style chat application. Inspired by all the hacker/superhero movies, I supports chats, groups, fullscreen mode and much more.`,
        github: 'https://github.com/AksBad007/chatter-type/',
        demo: 'https://fast-four-walnut.glitch.me/',
    },
    {
        imgSrc: Zillit,
        title: 'Zillit',
        desc: `ZILLIT App is all encompassing communication tool for the Entertainment Industry professionals and
                for professionals in Other fields.`,
        demo: 'https://apps.apple.com/in/app/zillit/id6443424188/',
    },
    {
        imgSrc: KISS,
        title: 'Project K.I.S.S. - Keep It Simple Stupid',
        desc: `The project is aimed to provide an immersive experience for 
                frontend practice (HTML, CSS and JavaScript) along with the presence of a Music 
                Player and a To-Do List.`,
        github: 'https://github.com/AksBad007/Project-K.I.S.S./',
        demo: 'https://project-k-i-s-s.vercel.app/',
    },
]

export const skillsData = [
    {
        domain: 'Programming Languages',
        skills: [
            {
                name: 'HTML',
                lvl: 'Experienced',
            },
            {
                name: 'CSS',
                lvl: 'Experienced',
            },
            {
                name: 'Sass',
                lvl: 'Intermediate',
            },
            {
                name: 'JavaScript',
                lvl: 'Experienced',
            },
            {
                name: 'TypeScript',
                lvl: 'Intermediate',
            },
            {
                name: 'Python',
                lvl: 'Experienced',
            },
            {
                name: 'PHP',
                lvl: 'Beginner',
            },
            {
                name: 'SQL',
                lvl: 'Intermediate',
            },
            {
                name: 'Bash',
                lvl: 'Beginner',
            },
        ],
    },
    {
        domain: 'Frontend Development',
        skills: [
            {
                name: 'React.js',
                lvl: 'Experienced',
            },
            {
                name: 'Angular',
                lvl: 'Intermediate',
            },
            {
                name: 'Vue.js',
                lvl: 'Beginner',
            },
            {
                name: 'Gatsby',
                lvl: 'Experienced',
            },
            {
                name: 'Next.js',
                lvl: 'Experienced',
            },
            {
                name: 'Bootstrap',
                lvl: 'Experienced',
            },
            {
                name: 'Tailwind CSS',
                lvl: 'Intermediate',
            },
            {
                name: 'JQuery',
                lvl: 'Intermediate',
            },
            {
                name: 'React Native',
                lvl: 'Intermediate',
            },
            {
                name: 'WebFlow',
                lvl: 'Beginner',
            },
        ],
    },
    {
        domain: 'Backend Development',
        skills: [
            {
                name: 'Node.js',
                lvl: 'Experienced',
            },
            {
                name: 'Express.js',
                lvl: 'Experienced',
            },
            {
                name: 'Flask',
                lvl: 'Experienced',
            },
            {
                name: 'Django',
                lvl: 'Beginner',
            },
            {
                name: 'Socket.IO',
                lvl: 'Intermediate',
            },
            {
                name: 'Strapi',
                lvl: 'Beginner',
            },
            {
                name: 'Contentful',
                lvl: 'Beginner',
            },
            {
                name: 'NGINX',
                lvl: 'Intermediate',
            },
            {
                name: 'AWS',
                lvl: 'Intermediate',
            },
            {
                name: 'Firebase',
                lvl: 'Intermediate',
            },
        ],
    },
    {
        domain: 'Miscellaneous',
        skills: [
            {
                name: 'MongoDB',
                lvl: 'Intermediate',
            },
            {
                name: 'Electron.js',
                lvl: 'Beginner',
            },
            {
                name: 'WordPress',
                lvl: 'Intermediate',
            },
            {
                name: 'WooCommerce',
                lvl: 'Beginner',
            },
            {
                name: 'Linux',
                lvl: 'Experienced',
            },
            {
                name: 'Git',
                lvl: 'Experienced',
            },
            {
                name: 'Jira',
                lvl: 'Intermediate',
            },
            {
                name: 'CI/CD',
                lvl: 'Beginner',
            },
        ],
    },
]

export const footerSocials = [
    LinkedInSocial,
    {
        link: 'https://twitter.com/AksBad007/',
        icon: <BsTwitterX />,
        label: 'X (Twitter)',
    },
    {
        link: 'https://www.upwork.com/freelancers/~01dfd3051608186118/',
        icon: <SiUpwork />,
        label: 'UpWork',
    },
]

export const intro = `I'm a Software Engineer with expertise in Full Stack Web Development.
                        From crafting crafting visually stunning websites to developing developing secure and robust web applications,
                        my experience is a tapestry woven with diverse projects.`

export const totalProjects = projectsData.length

export const contactDetails = [
    {
        icon: <MdCalendarMonth className='contact__option-icon' />,
        type: 'Schedule a Meeting',
        label: 'Calendly',
        get link () {
            return {
                url: `https://calendly.com/akshat-arora/project-overview/`,
                text: "Let's Connect",
            }
        },
    },
    {
        icon: <MdOutlineEmail className='contact__option-icon' />,
        type: 'Email',
        label: 'hello@akshatarora.in',
        get link () {
            return {
                url: `mailto:${ this.label }`,
                text: "Send a Message",
            }
        },
    },
    {
        icon: <HiOutlinePhone className='contact__option-icon' />,
        type: 'Phone',
        label: '+91 99154-29585',
        get link () {
            return {
                url: `tel:${ this.label }`,
                text: "Contact Me",
            }
        },
    },
]

export const query = `
    {
        publication(host: "aksbad007.hashnode.dev") {
            url
            title
            posts(first: 3) {
                edges {
                    node {
                        title
                        slug
                        publishedAt
                        seo {
                            title
                            description
                        }
                        coverImage {
                            url
                        }
                    }
                }
            }
        }
    }
`

export const isInViewport = (element) => {
    const rect = element.getBoundingClientRect()

    return (
        rect.top <= window.innerHeight / 3 ||
        rect.bottom <= window.innerHeight / 3
    )
}

export const redirect = (destination) => document.getElementById(destination).scrollIntoView({ behavior: 'smooth', block: 'center' })
